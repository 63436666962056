<template>
  <div class="add-entities-container">
    <p>Coming Soon...</p>
  </div>
</template>

<script>
export default {
  name: 'AddEntities'
}
</script>
<style lang="scss" scoped>
.team-container {
  font-size: 20px;
}
</style>
